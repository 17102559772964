.testimonial-wrap {
  .testimonial-quote {
    //min-height: 160px;
  }

}

.carousel .slide {
  background: none !important
}


.content {
  text-align: left;
}
