.skills {
  .row > div {
    margin-bottom: 10px;
  }

  .progress {
    height: 6px;
  }

  .progress-bar {
    background-color: $base-color;
  }
  margin-bottom: 30px;
}
