.works-items.projects {
  .image {
    width: 100%;
    position: relative;
    padding-bottom: 100%;
    overflow: initial;
    .flip-card {
      background-color: transparent;
      width: 100%;
      height: 98%;
      position: absolute;
      perspective: 1000px;
      &:hover{
        .flip-card-inner {
          transform: rotateY(180deg);
        }
      }

      .flip-card-front, .flip-card-back {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
      }

      .flip-card-front {
        background-color: #bbb;
        color: black;
      }

      .flip-card-back {
        background-color: $base-color;
        color: white;
        padding: 20px;
        transform: rotateY(180deg);

        .btn-custom.width-100 {
        }
      }


      .flip-card-inner {
        position: relative;
        width: 100%;
        height: 100%;
        transition: transform 0.6s;
        transform-style: preserve-3d;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      }
    }
  }
}

