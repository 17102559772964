@media screen and (max-width: 991px) {
  .about .hero,
  .contact .contact-info {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 768px) {

  .form-group.col-md-9 {
    margin-bottom: 20px !important;
  }

  .header-left {
    transform: translateX(-100px);

    .logo,
    .btn-share {
      display: none;
    }

    .main-menu {
      height: 100%;
      width: 100%;
      transform: rotate(0deg) translateX(0%);
      background-color: $white-color;
      justify-content: center;
      display: flex;

      .cross {
        display: block;
      }

      .menu, .nav-item {
        width: 100%;
      }

      .menu-nav {
        flex-direction: column;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;

        .nav-link {
          width: 100%;
          padding: 15px 0;
          line-height: 1.5;

          &.active:after, &:hover:after {
            bottom: 0;
          }
        }
      }
    }
  }

  .social-footer {
    display: none !important;
  }
  .main {
    margin-left: 0;
  }

  .menu-toggle {
    display: block;
  }
}

@media screen and (max-width: 567px) {
  .modal-dialog {
    .tech {
      width: 16.666667% !important;
    }
    .client {
      width: 25% !important;
    }
  }
  .timeline {
    .tech {
      width: 12.5% !important;
    }
  }
  .client {
    width: 20%;
  }
  h1 {
    font-size: $f-s-h2;
  }

  h2 {
    font-size: $f-s-h3;
  }

  .max-width-450 {
    width: auto;
  }

  .section {
    padding-left: 0;
    padding-right: 0;
  }
  .home {
    padding: 0;
    background-position: unset !important;
  }
  .home .banner h1.cd-headline {
    font-size: $f-s-h2;
  }

  .my-info {
    display: none;
  }
  .about .details .info li {
    width: 100%;
  }

  .testimonials .item .content .data {
    flex-direction: column;
  }

  .btn-custom {
    padding: 8px 12px;
    font-size: 14px;
  }

  .cd-words-wrapper {
    display: block !important;
  }
}

@media screen and (max-width: 350px) {
  .about, #contact {
    .btn-custom {width:100%}
  }
  .hire_me {
    width: 100%;
    margin-top: 10px;
    margin-left:0 !important;
  }
}
