/* ------------------------------
		 Works
------------------------------ */
.works{
	.item{
        margin-bottom: 30px;
        border-radius: $rd-round;
        //@include shadow();
        .image{
            position: relative;
            //overflow: hidden;
            img{
                @include ease(0.6);
            }
            .overly{
                opacity: 0;
			    position: absolute;
			    width:100%;
			    height: 100%;
			    top:0;
                left:0;
                display: flex;
                align-items: center;
                justify-content: center;
                @include ease(0.7);
                .view-work{
                    color:$white-color;
                    border:1px solid $white-color;
                    padding: 6px 16px;
                    border-radius: $rd-round;
                }
            }
            &:hover{
			    img{
				    //transform: scale(1.2);
			    }
			    .overly{
				    background-color: rgba($dark-color, 0.5);
				    opacity: 1;
			    }
		    }
        }
        .details{
            padding: 20px;
            i{
                color:$base-color;
            }
        }
    }
}
