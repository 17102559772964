/* -----------------------------------
		    Header Left
----------------------------------- */
.header-left{
	position: fixed;
	width:100px;
	height:100vh;
    background-color: $white-color;
	border-right:1px solid $bg-light;
	z-index: 999;
	@include ease(0.5);
	&.open{
		transform: translateX(0px);
		width:100%;
	}
}


.logo{
	position: absolute;
	top:30px;
	left:0;
	right:0;
	text-align: center;
	border-radius: $rd-circle;
	width:40px;
	height: 40px;
	margin: auto;
	overflow: hidden;
	box-shadow: 0 4px 6px -1px rgba($base-color, 0.1), 0 2px 4px -1px rgba($base-color, 0.06);
	a{
		font-weight: $f-w-500;
		font-family: $heading-font;
		font-size: 18px;
		line-height: 40px;
		text-align: center;
	}
}


.btn-share{
	position: absolute;
	bottom:30px;
	left:0;
	right:0;
	line-height: 1;
	text-align: center;
	span{
		font-size: 20px;
	}
}







