// Font Family
$heading-font:'Poppins', sans-serif;
$body-font: 'Roboto', sans-serif;

// Theme Color
//$base-color:#f21e4e;
$base-color:#17a2b8;
$pink-color:#f21e4e;
$red-color: #900;
$light-b-color:rgba($base-color,0.1);
$white-color: #ffffff;
$black-color: #000000;
$dark-color: #222222;
$dark-color-0:#343a40;
$dark-color-1: #929292;
$muted-color:#6c757d;
$eee: #eee;
$bg-light:#f8f9fa;

//font-size
$f-s-h1: 40px;
$f-s-h2: 32px;
$f-s-h3: 28px;
$f-s-h4: 24px;
$f-s-h5: 20px;
$f-s-h6: 16px;
$f-s-p: 16px;

//font-weight
$f-w-300: 300;
$f-w-400: 400;
$f-w-500: 500;
$f-w-600: 600;
$f-w-700: 700;
$f-w-800: 800;
$f-w-900: 900;

//Line-height
$line-height-base: 1.618;
$line-height: 1.5;

//border-raduis
$rd-round:5px;
$rd-circle: 50%;




