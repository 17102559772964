.tooltip-content {
  text-align: left;
  white-space: pre-wrap;
}

.techs {
  margin-bottom: 16px;
}

.tech {
  width: 12.5%;
  background-color: $light-b-color;
  float: left;

  a {
    display: block;
    width: 100%;
    padding-bottom: 100%;
    filter: grayscale(100%);
    background-size: cover;
    opacity: 0.5;
    &:hover {
      opacity: 1;
      filter: none
    }
  }

}

.tooltip-inner {
  max-width: 350px;
  /* If max-width does not work, try using width instead */
  width: 350px;
}
