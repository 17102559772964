#testimonial_request {
  position: fixed;
  width: 350px;
  min-height: 100px;
  bottom: 100px;
  right: -360px;
  background: $base-color;
  border-radius: 5px;
  color: #fff;
  padding: 20px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  transition: right .3s ease-in-out;
  z-index: 999999;
  max-width: calc(100vw - 60px);
  text-transform: none !important;

  .hide_testimonial_request {
    color: white;
    display:block;
    padding: 20px 0;
  }

  &.active {
    right: 30px;
  }

  &.stranger {
    right: -360px !important;
  }
}
