/* -----------------------------------------------
				CSS Styles
--------------------------------------------------

    Template Name: Mariam - Personal Portfolio Template

--------------------------------------------------

Table of Content

	- Global
	- Header Left
	- Menu
	- Home
	- About
	- Services
	- Works
    - Testimonials
    - Blog
	- Contact
	- Preloader
	- Responsive


----------------------------------- */

/* -----------------------------------
		 Global
----------------------------------- */
* {
  margin: 0;
  padding: 0;
  font-family: inherit;
  outline: none !important;
  list-style: none !important;
  text-decoration: none !important;
}

*:after,
*:before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  font-family: $body-font;
  background-color: $white-color;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $heading-font;
  font-weight: $f-w-600;
  line-height: $line-height-base;
}

h1 {
  font-size: $f-s-h1;
}

h2 {
  font-size: $f-s-h2;
}

h3 {
  font-size: $f-s-h3;
}

h4 {
  font-size: $f-s-h4;
}

h5 {
  font-size: $f-s-h5;
}

h6 {
  font-size: $f-s-h6;
}

a {
  outline: none !important;
  @include ease(0.5);

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.base-color {
  color: $base-color !important;
}

.bg-base-color {
  background-color: $base-color;
}

.font-w-600 {
  font-weight: $f-w-600;
}

.font-w-700 {
  font-weight: $f-w-700;
}

.max-width-450 {
  max-width: 450px;
}

.full-width {
  width: 100%;
}

img {
  max-width: 100%;
  height: auto;
}

ul {
  margin-bottom: 0;
}

.list-inline li {
  display: inline-block;
}

.main {
  height: 100%;
  margin-left: 100px;
  @include ease(0.5);
}

.section {
  padding: 70px 40px 0px 40px;

  &-title {
    position: relative;
    padding-left: 15px;
    z-index: 1;

    &:after {
      @include after(0px, 6px, 40px, 40px);
      background-color: $light-b-color;
      border-radius: $rd-circle;
      z-index: -1;
    }

    h2 {
      text-transform: capitalize;
      font-weight: $f-w-700;
    }

    p {
      text-transform: capitalize;
    }
  }
}

.social-footer {
  display: none;
  position: fixed;
  bottom: 25px;
  z-index: 99;
  margin-left: 20px;
  text-align: center;
  background-color: transparent;
  @include social-media;
  @include easeInOut(0.5);

  &.active {
    display: block;
  }

  a {
    width: 30px;
    height: 30px;
    background-color: $white-color;
    border-radius: $rd-circle;
    text-align: center;
    margin: auto;
    display: inline-block;
    @include ease(0.7);
    @include shadow;

    i {
      line-height: 30px;
      text-align: center;
    }
  }
}

/* ---------- Buttons ---------  */

.btn-custom {
  cursor: pointer;
  display: inline-flex;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  position: relative;
  background-color: $base-color;
  line-height: 24px;
  border: 0;
  color: $white-color;
  font-size: $f-s-p;
  font-weight: $f-w-500;
  padding: 10px 24px;
  text-transform: capitalize;
  border-radius: $rd-round;
  z-index: 1;
  @include ease(0.3);

  &.hire_me {
    margin-left: 10px;
  }

  &.white {
    background-color: white;
    color: #000 !important;

    &:hover {color: white !important}
  }

  &.margin {
    margin: 10px;
  }


  &.width-100 {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.red {
    background-color: $red-color;
  }

  &.grey {
    background-color: $muted-color;
  }


  &.pink {
    background-color: $pink-color;
  }

  i {
    margin-right: 8px;
  }

  .arrow_right {
    margin-top: 6px;
    margin-left: 5px;
  }

  &::after {
    @include after(0px, 0px, 100%, 100%);
    background-color: $dark-color-0;
    z-index: -1;
    transform-origin: center;
    transform: scale(0);
    border-radius: $rd-round;
    @include ease(0.3);
  }

  &:hover::after,
  &:focus::after {
    transform: scale(1);
  }

  &:hover,
  &:focus {
    color: $white-color;
    outline: none;
  }
}

/* ---------- Custom Margin Top --------- */
@for $x from 0 through 20 {
  .mt-#{$x*5} {
    margin-top: $x*5px !important;
  }
}

/* Custom Margin Bottom */
@for $x from 0 through 20 {
  .mb-#{$x*5} {
    margin-bottom: $x*5px !important;
  }
}

/* Custom Padding top */
@for $x from 0 through 20 {
  .pt-#{$x*5} {
    padding-top: $x*5px !important;
  }
}

/* Custom Padding top */
@for $x from 0 through 20 {
  .pb-#{$x*5} {
    padding-bottom: $x*5px !important;
  }
}






