@import'abstracts/variables';
@import'abstracts/functions';
@import'abstracts/mixins';

@import'base/reset';
@import'base/fonts';
@import'base/animation';
@import'base/global';
@import'base/utilities';

@import'layout/all';

@import'components/all';

@import'pages/all';

@import'common';

@import'template/main.scss';

body {
  padding: 0 !important;
  margin: 0;
  font-size: 15px;
  font-weight: 300;
  background: #fafafa;

  div {
    white-space: pre-wrap;
  }

}

.unreset {
  li {
    list-style-type: disc !important;
    margin-left: 16px !important;
    margin-bottom: 4px !important;
  }
}

.about {
  p {
    margin-bottom: 3px;
    line-height: 1.2;
  }
}